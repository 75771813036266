import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"

const NQPage = ({ data, location }) => {
  return (
    <Layout location={location}>
      <SEO title="What is NQ?" />
      {/* <div className="page-headline">
        <div className="container">
          <div className="section-heading text-center">
            <h6 className="font-weight-bold text-uppercase text-white-50 flair">
              {page.title}
            </h6>
          </div>
        </div>
      </div> */}
      <div className="page-content nq-page">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-12 mb-5">
              <div className="page-content-title text-center">
                <h1 className="section-title text-dark text-shadow f-700">
                  What is NQ?
                </h1>
              </div>
            </div>

            <div className="col-md-10 mx-auto">
              <div className="nq-content text-center">
                <h3>
                  Nutrient Quotient (NQ) is a science- based methodology that
                  most accurately tracks consumers’ complete nutrients
                  consumption presented via a personalized NQ score for each
                  consumed meal.
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NQPage
